<template>
    <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
                <div class="row">
                  <div class="col">
                    <label for="" class="form-label">Paket Resmi</label>
                      <el-upload
                        class="avatar-uploader mb-8"
                        :on-success="handleImageSuccess"
                        name="image"
                        :action="apiBaseurl + '/imageUpload?type=package'"
                        :show-file-list="false">
                      <img  v-if="paket.image" :src="paket.image" class="avatar"/>
                      <el-icon v-else
                              class="avatar-uploader-icon d-flex justify-content-center align-items-center align-content-center">
                        <Plus/>
                      </el-icon>
                    </el-upload>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label for="" class="form-label">Paket İkonu</label>
                      <el-upload
                        class="avatar-uploader mb-8"
                        :on-success="handleIconSuccess"
                        name="image"
                        :action="apiBaseurl + '/imageUpload?type=package'"
                        :show-file-list="false">
                      <img  v-if="paket.icon" :src="paket.icon" class="avatar"/>
                      <el-icon v-else
                              class="avatar-uploader-icon d-flex justify-content-center align-items-center align-content-center">
                        <Plus/>
                      </el-icon>
                    </el-upload>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                      <label for="" class="form-label">Paket Rengi</label>
                      <input type="color" v-model="paket.color" class="form-control form-control-color " style="width:90%;" id="exampleColorInput">
                  </div>
                </div>
            </div>
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-12">
                    <div class="mb-10 fv-row fv-plugins-icon-container">
                        <!--begin::Label-->
                        <label class="required form-label">Paket Adı</label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <input type="text"  v-model="paket.title" class="form-control form-control-solid"  placeholder="Paket İsmi" />
                        <!--end::Input-->
                    </div>
                </div>   
                
                
              </div>
              <!--begin::Input group-->

              <div class="row">
                  <div :class="{ 'col-md-4': paket.status == 'SCHEDULED' , 'col-md-6' : paket.status != 'SCHEDULED' }">
                    <div class="mb-10">
                      <!--begin::Label-->
                        <label class="form-label"> Paket Kategori</label>
                        <!--end::Label-->
                      <div >
                        <el-select
                            v-model="paket.categories"
                            multiple
                            filterable
                            allow-create
                            style="width:100%;"
                            default-first-option
                            :reserve-keyword="false"
                            placeholder="Paket Kategorisi"
                          >
                            <el-option
                              v-for="item in productCategories"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        

                      </div>
                    </div>
                  </div>
                  <div  :class="{ 'col-md-4': paket.status == 'SCHEDULED' , 'col-md-6' : paket.status != 'SCHEDULED' }">
                    <div class="mb-10">
                      <!--begin::Label-->
                        <label class="form-label"> Paket Durumu</label>
                        <!--end::Label-->
                      <div >
                        <el-select
                            v-model="paket.status"
                            filterable
                            default-first-option
                            style="width:100%;"
                            :reserve-keyword="false"
                            placeholder="Choose tags for your article">
                            <el-option
                              v-for="item in paketStatus"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        

                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                      <div class="demo-date-picker" v-show="paket.status === 'SCHEDULED' ">
                              <label class="form-label"> Paket Durumu</label>
                          <div>
                        
                              <el-date-picker
                                  v-model="paket.publishDate"
                                  type="date"
                                  style="width:100%;"
                                  placeholder="Yayınlama Tarihi Seçiniz"
                                  :default-value="new Date()"
                              />
                          </div>
                          
                      </div>
                  </div>

              </div>
              <div>
                  <!--begin::Label-->
                  <label class="form-label">Paket Tanımı</label>
                  <!--end::Label-->
                  <!--begin::Editor-->
                  <textarea
                      v-model="paket.explanation"
                      class="form-control form-control-solid"
                      type="textarea"
                      rows="9"
                      placeholder="Paket Tanımı!">
                      </textarea>
                  <!--end::Description-->
              </div>
            </div>
          </div>
          
        <!--end::Input group-->
        </div>
    </div>
</template>

<script>

import { Actions, Mutations } from "@/store/enums/StoreEnums";
import store from '@/store';
import  useCrud  from '@/composables/useCrud.js';
import { onMounted, ref } from 'vue';
import { Plus } from '@element-plus/icons-vue';
export default {
    
    setup() {
        const paket = ref({});
        const productCategories = ref([]);
        const { loadCrud } = useCrud();
        const apiBaseurl = ref(process.env.VUE_APP_API_URL);

        const paketStatus = ref([{label:'Hızlı Yayın',value:'PUBLISHED'},{label:'Pasif',value:'INACTIVE'},{label:'İleri Tarihli',value:'SCHEDULED'}]);
        
        

        const handleImageSuccess = ( response, uploadFile ) => {
          console.log(response);
          //TODO
          paket.value.image = response.data.image;
        }
        const handleIconSuccess = ( response, uploadFile ) => {
          console.log(response);
          //TODO
          paket.value.icon = response.data.image;
        }

        
        onMounted(() => {
            paket.value = store.getters.packageItem;
            loadCrud('categories').then(function(response){
                if(response.success){
                    response.data.forEach((value)=> {
                      productCategories.value.push({label:value.title,value:value.categoryId});
                    });
                }
            });
        }); 
        return {
            paket,
            productCategories,
            paketStatus,
            handleImageSuccess,
            apiBaseurl,
            handleIconSuccess
        }
    },
    components:{
      Plus,
    },
}
</script>

<style>
  .avatar-uploader {
    width: 90%;
    height: 180px;
    border-radius: 6px;
    border: 1px dashed #d9d9d9;
    display:flex;
    align-items:center;
    justify-content:center;
    align-content:center;
    overflow:hidden;
  }
  .avatar-uploader img{
    width:100%;
    height:100%;
  }
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    transition: var(--el-transition-duration-fast);
  }
  .avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
  }
  .el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
  }

  .el-select .el-input__inner {
    background-color: #F5F8FA !important;
    border-color: #F5F8FA  !important;
    color: #5E6278  !important;
    transition: color 0.2s ease, background-color 0.2s ease  !important;

  }
</style>